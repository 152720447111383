import React from "react";

import Layout from "layouts";
import Hero from "./dvpn/hero.section";
import SecurityHero from "./dvpn/security.hero.section";
import SecurityInfo from "./dvpn/security.info.section";
import DApp from "./dvpn/dApp.section";
import Payment from "./dvpn/payment.section";
import EcoSystem from "./dvpn/eco.section";
import Mission from "./dvpn/mission.section";
import "./dvpn/index.scss";
import { Helmet } from 'react-helmet'

const Index = () => {
  return (
    <Layout>
      <Helmet title={'MASQ Network | VISION'} defer={false} />
      <div className="DVPN">
        <Hero />
        <EcoSystem />
        <SecurityInfo />
        <DApp />
        <Mission />
        {/* <Payment />
        <SecurityHero /> */}
      </div>
    </Layout>
  );
};

export default Index;